import { baseEnvironment, Environment } from './environment.base';

export const environment: Environment = {
  ...baseEnvironment,

  env: 'dev',

  api: {
    host: 'https://api.dev.employee-portal.rvprintfactory.com'
  },

  auth: {
    region: 'eu-central-1',
    pool_id: 'eu-central-1_tyIyCzOre',
    client_id: '3cg80auebl1j2dj9ouc0oq3qb3',
    EMPLOYEE_DEFAULT_PASSPHRASE: '1Password',
  },
};
